import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'dialogs-helpbox-layout',
	templateUrl: './dialogs-helpbox-layout.component.html',
	styleUrls: ['./dialogs-helpbox-layout.component.scss'],
	standalone: false,
})
export class DialogsHelpboxLayoutComponent {
	@Input() content: string = '';
	@Input() helpOpen!: boolean;
	@Output() helpOpenChange = new EventEmitter<boolean>();

	public toggleHelp() {
		this.helpOpen = !this.helpOpen;
		this.helpOpenChange.emit(this.helpOpen);
	}
}
