import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface FeatureFlagStorage {
	flag: string;
	value: boolean;
}

export interface FeatureFlag {
	name: string;
	defaultValue: boolean;
	currentValue: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class FeatureFlagService {
	protected readonly features: { [key: string]: boolean } = environment.feature;

	constructor() {}

	public getFeatureFlags(): FeatureFlag[] {
		const featureFlags = Object.entries(this.features).map(([key, value]) => ({
			name: key,
			defaultValue: value,
			currentValue: value,
		}));

		const storedPreferences = localStorage.getItem('preferences');
		if (storedPreferences) {
			let overrides;
			try {
				overrides = JSON.parse(storedPreferences) as { featureFlags?: FeatureFlagStorage[] };
			} catch (e) {
				return featureFlags;
			}

			if (overrides.featureFlags && Array.isArray(overrides.featureFlags)) {
				for (const override of overrides.featureFlags) {
					const flag = featureFlags.find((f) => f.name === override.flag);
					if (flag) {
						flag.currentValue = override.value;
					}
				}
			}
		}

		return featureFlags;
	}

	public overrideFlag(flag: FeatureFlag): void {
		const localStorageFlag: FeatureFlagStorage = { flag: flag.name, value: flag.currentValue };
		const prefs: { featureFlags: FeatureFlagStorage[] } = { featureFlags: [localStorageFlag] };

		const storedPreferences = localStorage.getItem('preferences');
		if (storedPreferences) {
			try {
				const parsedPrefs = JSON.parse(storedPreferences) as { featureFlags?: FeatureFlagStorage[] };

				if (parsedPrefs.featureFlags && Array.isArray(parsedPrefs.featureFlags)) {
					prefs.featureFlags = parsedPrefs.featureFlags.filter((f) => f.flag !== flag.name);

					if (flag.currentValue !== flag.defaultValue) {
						prefs.featureFlags.push(localStorageFlag);
					}
				}
			} catch (error) {
				console.error('Error parsing stored preferences:', error);
			}
		}

		localStorage.setItem('preferences', JSON.stringify(prefs));
	}

	public getFeatureFlagState(flag: keyof typeof environment.feature): boolean {
		const featureFlags = this.getFeatureFlags();
		const featureFlag = featureFlags.find((f) => f.name === flag);
		return featureFlag ? featureFlag.currentValue : this.features[flag];
	}
}
