import { HttpErrorResponse } from '@angular/common/http';
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { CustomerdbService } from 'src/app/components/services/customer-db/services/customer-db.service';

export interface SelectionEvent {
	name: string;
	id: string;
}

@Component({
	selector: 'mcm-customer-select',
	templateUrl: './customer-selector.component.html',
	styleUrls: ['./customer-selector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: CustomerSelectorComponent,
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: CustomerSelectorComponent,
			multi: true,
		},
	],
	standalone: false,
})
export class CustomerSelectorComponent implements AfterViewInit, ControlValueAccessor, OnDestroy, OnInit, OnChanges {
	@Input() readonly: boolean = false;
	@Input() appearance: MatFormFieldAppearance = 'fill';
	@Input() hideHint: boolean = false;
	@Input() initialValue: string = '';
	@Input() useSnowID: boolean = false;
	@Input() allowWildcard: boolean = false;

	@ViewChild(MatAutocompleteTrigger) trigger?: MatAutocompleteTrigger;
	@ViewChild(MatInput) matInput?: MatInput;

	@Output() tenantChanged: EventEmitter<SelectionEvent> = new EventEmitter<SelectionEvent>();
	@Output() selectionFinalized: EventEmitter<void> = new EventEmitter();

	public wildcardOption: SelectionEvent = { id: '*', name: '**All Tenants**' };
	public filteredOptions: SelectionEvent[] = [];
	public optionSelected?: SelectionEvent = undefined;
	public tenantLabel!: string;

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onChange = (val: any) => {};
	onTouched = () => {};

	loading = false;

	private _onChange: Subject<SelectionEvent> = new Subject<SelectionEvent>();
	private _subscriptions: Subscription[] = [];

	constructor(
		private injector: Injector,
		private customerDb: CustomerdbService
	) {
		// Debouncing subscription
		this._subscriptions.push(
			this._onChange
				.asObservable()
				.pipe(
					filter<SelectionEvent>(Boolean),
					tap(() => (this.loading = true)),
					debounceTime(300),
					tap(() => (this.loading = false)),
					distinctUntilChanged(),
					tap((value: SelectionEvent) => {
						this.optionSelected = value;
						this.onChange(value.id);
						this.tenantChanged.emit(value);
						this.searchCustomers(value.name);
					})
				)
				.subscribe()
		);
	}

	async ngOnInit(): Promise<void> {
		if (this.useSnowID !== true) {
			this.tenantLabel = 'Tenant ID';
		} else {
			this.tenantLabel = 'Snow ID';
		}
	}

	ngOnDestroy(): void {
		this._subscriptions.forEach((sub) => sub.unsubscribe());
	}

	public get value(): string {
		return this.optionSelected?.id || '';
	}

	ngAfterViewInit() {
		this.trigger!.panelClosingActions.subscribe(() => {
			if (this.trigger!.activeOption) {
				const value = this.trigger!.activeOption.value;
				this.writeValue(value);
				this.onChange(value);
			}
		});

		// this is needed in order for the mat-form-field to be marked as invalid when the control is invalid
		setTimeout(() => {
			this.matInput!.ngControl = this.injector.get(NgControl, null)!;
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.options) {
			// this.searchCustomers(this.optionSelected);
		}
	}

	writeValue(obj: any): void {
		if (obj && this.trigger) {
			this.trigger.writeValue(obj);
			this.optionSelected = obj;
			this.searchCustomers(obj);
		} else if (obj && !this.trigger) {
			this.initialValue = obj;
			this.optionSelected = obj;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// if controls are not present, they are kind of disabled
		if (this.matInput) {
			this.matInput.disabled = isDisabled;
		}

		if (this.trigger) {
			this.trigger.setDisabledState(isDisabled);
		}
	}

	validate(): { [key: string]: any } {
		return {};
	}

	valueChanged(event: any) {
		this._onChange.next({ id: event.target.value, name: event.target.value });
		if (event.target.value === '*') {
			this.trigger!.closePanel();
		}
	}

	onOptionSelected(event: any) {
		const value = event.option.value;
		this.optionSelected = value;
		this.onChange(value.id);
		this.tenantChanged.emit(value);
		this.selectionFinalized.emit();
	}

	async searchCustomers(customer: string) {
		this.loading = true;

		try {
			this.filteredOptions = (await this.customerDb.searchCustomer(customer)).map((option) => {
				let displayName = option.name;

				if (option.businessUnitName) {
					displayName += ` [${option.businessUnitName}]`;
				} else if (this.useSnowID) {
					displayName += ` [${option.snowNumber}]`;
				}

				return { id: option.tenantId, name: displayName };
			});
		} catch (e) {
			if (e instanceof HttpErrorResponse) {
				console.log(e);
			}
		}
		this.loading = false;
	}

	resetSelection() {
		this.optionSelected = undefined;
		if (this.matInput) {
			this.matInput.value = '';
		}
		this.onChange('');
	}

	renderOption(option: SelectionEvent) {
		return `${option.name} | ${option.id}`;
	}
}
