import { Pipe, PipeTransform } from '@angular/core';
import { DateService } from 'src/app/services/date.service';

@Pipe({
	name: 'formattedDate',
	standalone: false,
})
export class CancomDatePipe implements PipeTransform {
	constructor(private dateService: DateService) {}

	transform(value: string | number | Date, type: 'date' | 'dateTime' | 'time' = 'date') {
		switch (type) {
			case 'dateTime':
				return this.dateService.formatDateTime(value);
			case 'time':
				return this.dateService.formatTime(value);
			case 'date':
			default:
				return this.dateService.formatDate(value);
		}
	}
}
