import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';

import { FeatureFlagService } from '../../my-profile/services/feature-flag.service';

export interface SubaccountType {
	subAccount: string;
	displayName: string;
	tenant: string;
}

export interface DescribeSubAccountType {
	subAccount: string;
	displayName: string;
	tenant: string;
	createdBy: string;
	createdAt: number;
	updatedAt: number;
}

@Injectable({
	providedIn: 'root',
})
export class IamAccountService {
	protected readonly api: string = environment.api.iam;
	private subaccountsEnabled = this.featureFlagService.getFeatureFlagState('subaccountsEnabled');

	constructor(
		private readonly http: HttpClient,
		private featureFlagService: FeatureFlagService
	) {
		const value = SERVICE_LOOKUP('iam');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	/**************************
	 * TENANTS
	 **************************/

	public async createTenant(email: string, customer: string, displayName: string): Promise<any> {
		return this.http
			.post(this.api + '/v1/Tenants', {
				email,
				customer,
				displayName,
				services: ['iam', 'drive'],
			})
			.toPromise();
	}

	public async listTenants(): Promise<any> {
		return this.http.get(this.api + '/v1/Tenants').toPromise();
	}

	public async describeTenant(tenant: string): Promise<any> {
		return this.http.get(this.api + '/v1/Tenants/' + tenant).toPromise();
	}

	public async updateTenantServices(tenant: string, services: string[]) {
		return this.http.put(this.api + '/v1/Tenants/' + tenant + '/services', services).toPromise();
	}

	public async deleteTenant(tenant: string) {
		return this.http.delete(this.api + '/v1/Tenants/' + tenant).toPromise();
	}

	/**************************
	 * SUBACCOUNTS
	 **************************/

	public defaultSubaccount: SubaccountType = { displayName: 'Default (Main Account)', subAccount: 'default', tenant: '' };

	public async createSubaccount(displayName: string): Promise<any> {
		return this.http.post(this.api + '/v1/Tenants/SubAccounts', { displayName }).toPromise();
	}

	public async createInternalSubaccount(displayName: string, callerPrincipal: string, tenant: string): Promise<any> {
		return this.http.post(this.api + '/v1/Tenants/SubAccounts', { displayName, callerPrincipal, tenant }).toPromise();
	}

	public async listSubaccounts(tenant?: string): Promise<SubaccountType[]> {
		if (this.subaccountsEnabled) {
			let params = new HttpParams();
			if (tenant) {
				params = params.append('tenant', tenant);
			}
			return this.http.get<SubaccountType[]>(this.api + '/v1/Tenants/SubAccounts', { params }).toPromise();
		} else {
			return [];
		}
	}

	public async describeSubaccount(subAccountId: string): Promise<any> {
		return this.http.get(this.api + '/v1/Tenants/SubAccounts/' + subAccountId).toPromise();
	}
}
