import { Component, Input } from '@angular/core';

export interface LandingPageDataType {
	serviceName: string;
	description: string;
	explanation: string;
	pricing: string;
	//documents: string;
	image: {
		url: string;
		title?: string;
	};
}

@Component({
	selector: 'landing-page',
	templateUrl: './landing-page.component.html',
	styleUrls: ['./landing-page.component.scss'],
	standalone: false,
})
export class LandingPageComponent {
	@Input() public data: LandingPageDataType = {
		serviceName: 'SERVICE NAME',
		description: 'DEFAULT SERVICE DESCRIPTION',
		explanation: `
			<p>
				A detailed explanation of this service and all other available services may be found in our
				<a href="https://docs.cancom.page/mcm-portal/" target="_blank" rel="noopener">documentation</a>.
			</p>
		`,
		pricing: `
			<p>
				All costs are pay-as-use and resources will be billed hourly.
			</p>
			<p>
				See the price list in the <a href="/metering-engine/price-list" target="_blank" rel="noopener">metering-engine</a> for details.
			</p>
		`,
		image: {
			url: 'assets/cloud-portal.svg',
		},
	};

	constructor() {}
}
