import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';
import { ILoadingOverlayParams } from 'ag-grid-community';

@Component({
	selector: 'app-loading-overlay',
	templateUrl: './cancom-table-loading.component.html',
	standalone: false,
})
export class CancomTableLoadingOverlay implements ILoadingOverlayAngularComp {
	public params?: ILoadingOverlayParams;
	public loadingMessage = 'Loading...';

	agInit(params: ILoadingOverlayParams): void {
		this.params = params;
	}
}
