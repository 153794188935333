import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

interface DisplayOptions {
	header?: string;
	alertText?: string;
	secret: string;
}

@Component({
	selector: 'app-onetime-viewer',
	templateUrl: './onetime-viewer.component.html',
	styleUrls: ['./onetime-viewer.component.scss'],
	standalone: false,
})
export class OnetimeViewerComponent implements OnInit {
	public header: string = 'ServiceUser Token created successfully ';
	public alertText: string =
		'Please write down or save the access key for your generated Service User, as you will not be able to view it later.\n Without this token you will not have access!';

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: DisplayOptions,
		private dialogRef: MatDialogRef<OnetimeViewerComponent>,
		private clipboard: Clipboard,
		private snackbar: MatSnackBar
	) {}

	ngOnInit(): void {
		if (this.data.header) {
			this.header = this.data.header;
		}
		if (this.data.alertText) {
			this.alertText = this.data.alertText;
		}
	}

	public close() {
		this.dialogRef.close();
	}

	public copyToClipboard(): void {
		this.clipboard.copy(this.data.secret);
		this.snackbar.open(`Secret copied to Clipboard.`, 'OK', { duration: 5000, horizontalPosition: 'end', verticalPosition: 'top' });
	}
}
