import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationBarService } from 'src/app/services/notification-bar.service';

interface TextInputeditorOptions {
	name: string;
	label?: string;
	value?: string;
	createField?: boolean;
	buttonText?: string;
	callback?: (val: string) => Promise<string | undefined>;
}

@Component({
	selector: 'app-textInputeditor-dialog',
	templateUrl: './textInputeditor-dialog.component.html',
	styleUrls: ['./textInputeditor-dialog.component.scss'],
	standalone: false,
})
export class TextInputeditorDialogComponent {
	public name: string = 'Text';
	public label: string = 'Text';
	public value: string = '';
	public buttonText: string = 'Update';

	public isUpdating = false;

	constructor(
		public dialogRef: MatDialogRef<TextInputeditorDialogComponent>,
		private notify: NotificationBarService,
		@Inject(MAT_DIALOG_DATA) public data: TextInputeditorOptions
	) {
		this.name = data.name;
		if (data.label) {
			this.label = data.label;
		} else {
			this.label = data.name;
		}
		if (data.value) {
			this.value = data.value;
		}
		if (data.createField) {
			this.buttonText = 'Create';
		}
		if (data.buttonText) {
			this.buttonText = data.buttonText;
		}
	}

	@ViewChild('autosize') autosize!: CdkTextareaAutosize;

	public async update(text: string) {
		this.isUpdating = true;
		let result: any = undefined;

		if (this.data.callback) {
			try {
				await this.data.callback(text);
			} catch (err) {
				console.log(err);
				if (err instanceof Error) {
					result = err.message;
				} else if (err instanceof HttpErrorResponse) {
					result = err.error.message || 'The service is currently not available. Please try again later.';
				} else {
					result = err;
				}
			}
		}

		console.log(result);
		if (result === undefined) {
			this.dialogRef.close(text);
		} else {
			this.notify.notify('error', 'Error', result);
		}

		this.isUpdating = false;
	}

	public cancel() {
		this.dialogRef.close();
	}
}
