import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DEFAULT_TABLE_ROWS, SERVICE_LOOKUP } from 'src/environments/global.config';

export type CustomerDbParams = {
	limit: number;
	startValue?: number;
};

export interface CustomerInformation {
	tenantId: string;
	name: string;
	snowNumber: string;
	snowSysId: string;

	shortName?: string;
	language?: string;
	businessUnitId?: string;
	businessUnitName?: string;
	businessUnitDescription?: string;
}

@Injectable({
	providedIn: 'root',
})
export class CustomerdbService {
	protected readonly api: string = environment.api.customerDb;
	public searchedCustomerValue?: string = undefined;

	constructor(protected readonly http: HttpClient) {
		const value = SERVICE_LOOKUP('customerDb');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	public async searchCustomer(
		customerName?: string,
		params: CustomerDbParams = { limit: DEFAULT_TABLE_ROWS }
	): Promise<CustomerInformation[]> {
		const url = `${this.api}/v2/Tenants`;
		let queryParams = new HttpParams().set('limit', params.limit);

		if (customerName) {
			queryParams = queryParams.append('filter', customerName);
		}

		if (params.startValue) {
			queryParams = queryParams.append('offset', params.startValue);
		}
		return this.http.get<any>(url, { params: queryParams }).toPromise();
	}

	public async syncCustomer(id: string): Promise<any> {
		return this.http
			.post<any>(`${this.api}/v2/Tenants/sync`, {
				snowId: id,
			})
			.toPromise();
	}

	public async getCustomerInfo(tenantId: string): Promise<CustomerInformation> {
		return this.http.get<any>(`${this.api}/v2/Tenants/${tenantId}`).toPromise();
	}

	public async createVirtualTenant(tenantName: string): Promise<any> {
		return this.http.post<any>(`${this.api}/v2/Tenants/addVirtualTenant`, { name: tenantName }).toPromise();
	}
}
