import { Component, ElementRef, OnInit, Optional, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { AgGridAngular, AngularFrameworkComponentWrapper, AngularFrameworkOverrides } from 'ag-grid-angular';
import { ColDef, DataTypeDefinition } from 'ag-grid-enterprise';
import { DEFAULT_TABLE_ROWS } from 'src/environments/global.config';

import { CancomTableLoadingOverlay } from './loading-overlay/cancom-table-loading.component';

@Component({
	selector: 'cancom-table',
	template: '',
	styleUrls: ['./cancom-table.component.scss'],
	providers: [AngularFrameworkOverrides, AngularFrameworkComponentWrapper],
	encapsulation: ViewEncapsulation.None,
	standalone: false,
})
export class CancomTableComponent extends AgGridAngular implements OnInit {
	// Add Custom Cell Data Types
	public dataTypeDefinitions: { [cellDataType: string]: DataTypeDefinition<any> } = {
		hyperLink: {
			extendsDataType: 'object',
			baseDataType: 'object',
			valueFormatter: (params) => (params.value == null ? '' : params.value.displayName),
			dataTypeMatcher: (value) => value && !!value.displayName && !!value.link,
		},
	};

	constructor(
		@Optional() elementRef: ElementRef,
		@Optional() viewContainerRef: ViewContainerRef,
		@Optional() angularFrameworkOverrides: AngularFrameworkOverrides,
		@Optional() angularFrameworkComponentWrapper: AngularFrameworkComponentWrapper
	) {
		super(elementRef, viewContainerRef, angularFrameworkOverrides, angularFrameworkComponentWrapper);

		// Set default theme and remove overwritten themes
		const classList = elementRef.nativeElement.classList as DOMTokenList;
		Array.from(classList).forEach((className) => {
			if (className.startsWith('ag-theme-')) {
				console.error('AgTheme provided. Please do not add any of the ag-theme styles.');
				classList.remove(className);
			}
		});
		classList.add('ag-theme-material');
	}

	ngOnInit(): void {
		const colDefPreset: ColDef = {
			sortable: true,
			filter: true,
			resizable: true,
			enableRowGroup: true,
			minWidth: 100,
			flex: 1,
			suppressHeaderFilterButton: true,
		};

		// Merge optional Component colDefs with our preset
		this.defaultColDef = { ...colDefPreset, ...this.defaultColDef };

		// Set default values
		this.paginationPageSize = DEFAULT_TABLE_ROWS;
		this.groupAllowUnbalanced = true;
		this.suppressMenuHide = false;
		this.loadingOverlayComponent = CancomTableLoadingOverlay;

		if (this.rowModelType === 'serverSide') {
			this.cacheBlockSize = DEFAULT_TABLE_ROWS;
		}
	}
}
