<div fxLayout="row" style="height: 100%">
	<div class="main-sidebar background-primary py1" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="8px">
		<div class="icon-text">
			<button mat-icon-button (click)="drawer.toggle()">
				@if (!drawer.opened) {
					<mat-icon>menu</mat-icon>
				}
				@if (drawer.opened) {
					<mat-icon>close</mat-icon>
				}
			</button>
			<span>SERVICES</span>
		</div>
		<div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="8px">
			@if (canEditEndpoint) {
				<div class="icon-text">
					<button mat-icon-button (click)="backendEndpointEditor()">
						<mat-icon>admin_panel_settings</mat-icon>
					</button>
					<span>LOCAL ENDPOINT EDITOR</span>
				</div>
			}
			<div class="icon-text">
				<a mat-icon-button href="https://docs.cancom.page/ccp/" target="_blank" rel="noopener">
					<mat-icon>description</mat-icon>
				</a>
				<span>DOCS</span>
			</div>
			<div class="icon-text">
				<a mat-icon-button href="mailto:{{ supportEMail }}" target="_blank" rel="noopener">
					<mat-icon>mail</mat-icon>
				</a>
				<span>CONTACT</span>
			</div>
		</div>
	</div>
	<mat-drawer-container class="main-drawer">
		<mat-drawer #drawer mode="over">
			<img class="toolbar-logo mt2 mx2" alt="CANCOM Logo" src="../assets/cancom-red.svg" />
			<mat-divider class="mx2"></mat-divider>
			<ng-container *skeleton="!(services.length > 1); repeat: 20; height: '20px'; className: 'm2'">
				<div class="service-list m2">
					@for (service of services; track service) {
						<div (click)="openService(service); drawer.close()" fxLayoutAlign="start center">
							@if (selectedService === service.displayName) {
								<mat-icon class="color-primary px1">arrow_forward</mat-icon>
							}
							<strong [ngClass]="{ 'color-primary': selectedService === service.displayName }">
								{{ service.displayName }}
							</strong>
						</div>
					}
				</div>
			</ng-container>
		</mat-drawer>
		<mat-drawer-content fxLayout="column" width="100%">
			<mat-toolbar class="main-toolbar" fxLayout="row" fxLayoutAlign="space-between center">
				<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
					<img class="toolbar-logo" alt="CANCOM Logo" src="../assets/cancom-red.svg" routerLink="/dashboard" />
					@if (stage === 'dev' || stage === 'staging') {
						<mat-chip disableRipple>
							{{ stage.toUpperCase() }}
						</mat-chip>
					}
				</div>
				<div fxLayout="row" fxLayoutGap="16px">
					<!-- Tenant Assumed Timeout -->
					@if (dateDiffRole === 0) {
						<div mat-button class="timeout-alert px2">ATTENTION! Role assumption expired</div>
					}
					<!-- Tenant -->
					@if (isLoggedIn) {
						@if ((userService.principalName | async) === (userService.userName | async)) {
							<button
								mat-button
								class="dark-button px2"
								[matMenuTriggerFor]="assumableRoles.length === 0 && wildcardRoles.length === 0 ? null : tenants"
							>
								<span fxLayout="row" fxLayoutAlign="start center">
									<span [ngClass]="{ loader: (userService.tenantName | async) === undefined }">
										@if (userService.impersonatedCRN) {
											{{ userService.impersonatedCRN.split(':')[1] }}
										} @else {
											{{ userService.tenantName | async }}
										}
									</span>
									@if (!(assumableRoles.length === 0 && wildcardRoles.length === 0)) {
										<mat-icon>keyboard_arrow_down</mat-icon>
									}
								</span>
							</button>
						}
						<!-- Tenant Assumed -->
						@else {
							<button mat-button class="dark-button pr2 pl0" [matMenuTriggerFor]="tenants">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									@if (dateDiffRole) {
										<button mat-button class="countdown-button px2">
											<countdown
												[config]="{ leftTime: dateDiffRole, format: 'mm:ss', notify: [300, 60] }"
												(event)="handleEventRole($event)"
											></countdown>
										</button>
									}
									{{ userService.tenantName | async }}
									@if (userService.subaccount | async; as subaccount) {
										({{ subaccount }})
									}
									- {{ userService.principalName | async }}
									<mat-icon>keyboard_arrow_down</mat-icon>
								</span>
							</button>
						}
						<!-- Tenant Menu -->
						<mat-menu class="tenant-menu" #tenants="matMenu" xPosition="before">
							<!-- Role assumed - menu for leave -->
							@if ((userService.sessionToken | async) && (userService.sessionToken | async) !== impersonatedToken) {
								<button mat-menu-item (click)="userService.leaveRole()">
									<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
										<mat-icon>exit_to_app</mat-icon>
										<span>Leave role</span>
									</span>
								</button>
								<mat-divider class="my1"></mat-divider>
							}
							<ng-container class="p2" (click)="$event.stopPropagation()">
								<!-- WILDCARD -->
								@if (wildcardRoles.length > 0) {
									<button mat-menu-item (click)="changeContext()">
										<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
											<mat-icon>manage_accounts</mat-icon>
											<span>Change Context</span>
										</span>
									</button>
									<mat-divider class="mb1"></mat-divider>
								}
								<!-- LAST USED ROLES -->
								@if (lastUsedRoles.length > 0 && !userService.impersonatedCRN) {
									<button mat-menu-item disabled style="min-height: 16px">Last used Roles</button>
									@for (obj of lastUsedRoles; track $index) {
										<button mat-menu-item [matMenuTriggerFor]="change">
											{{ obj.displayName || obj.tenant }}
										</button>
										<mat-menu #change="matMenu" class="fullwidth-menu" xPosition="before">
											@for (role of obj.roles; track $index) {
												<div fxLayout="row" fxLayoutAlign="start center">
													<button mat-menu-item (click)="assumeRoleSplit(obj.tenant, role.subaccount, role.role)">
														{{ role.role }}
														@if (role.subaccount) {
															<span>({{ role.subaccount }})</span>
														}
													</button>
													<button
														mat-icon-button
														class="mr1"
														(click)="removeLastUsedRole(obj.tenant, role.subaccount, role.role)"
													>
														<mat-icon>clear</mat-icon>
													</button>
												</div>
											}
										</mat-menu>
									}
									<mat-divider class="mb1"></mat-divider>
								}
								<!-- ASSUMABLE ROLES -->
								@if (assumableRoles.length > 0) {
									<button mat-menu-item disabled style="min-height: 16px">Assumable Tenants</button>
									@for (assumableRole of assumableRoles; track $index) {
										<button mat-menu-item (click)="changeContext(assumableRole)">
											{{ assumableRole.displayName }}
										</button>
									}
								}
							</ng-container>
						</mat-menu>
						<!-- Account -->
						<button
							mat-button
							[matMenuTriggerFor]="menu"
							class="round-button account-button"
							[ngClass]="isImpersonating ? 'pl0 pr2' : 'px2'"
						>
							<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
								@if (isImpersonating) {
									<button mat-button class="countdown-button px2">
										<countdown
											[config]="{ leftTime: dateDiffImpersonation, format: 'mm:ss', notify: [300, 60] }"
											(event)="handleEventImpersonation($event)"
										></countdown>
									</button>
									{{ userService.impersonatedName }}
								} @else {
									{{ userService.userName | async }}
								}
								<mat-icon>keyboard_arrow_down</mat-icon>
							</span>
						</button>
						<!-- Account Menu -->
						<mat-menu #menu="matMenu" xPosition="before" class="fullwidth-menu">
							<div class="m2" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8px">
								<div fxLayout="column">
									<span>Current Tenant ID:</span>
									<span style="font-weight: lighter">{{ userService.currentTenant }}</span>
								</div>
								<button
									mat-icon-button
									(click)="copyIdToClipboard()"
									matTooltip="Copy Tenant ID to Clipboard"
									disableRipple
								>
									<mat-icon style="font-size: 20px">content_copy</mat-icon>
								</button>
							</div>
							<mat-divider></mat-divider>
							@if (!dateDiffImpersonation) {
								<button mat-menu-item routerLink="my-profile">
									<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
										<mat-icon>account_circle</mat-icon>
										<span>My Profile</span>
									</span>
								</button>
								<button mat-menu-item (click)="createSessionToken()">
									<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
										<mat-icon>key</mat-icon>
										<span>Create Session Token</span>
									</span></button
								><button mat-menu-item (click)="logout()">
									<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
										<mat-icon>logout</mat-icon>
										<span>Logout</span>
									</span>
								</button>
							} @else {
								<button mat-menu-item (click)="userService.stopImpersonating()">
									<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
										<mat-icon>logout</mat-icon>
										<span>Stop Impersonating</span>
									</span>
								</button>
							}
							<button mat-menu-item (click)="themeService.toggleDarkTheme()">
								<span fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
									@if (!themeService.darkMode) {
										<mat-icon>dark_mode</mat-icon>
									}
									@if (themeService.darkMode) {
										<mat-icon>light_mode</mat-icon>
									}
									<span>Toggle {{ themeService.darkMode ? 'light' : 'dark' }} mode</span>
								</span>
							</button>
						</mat-menu>
					}
				</div>
			</mat-toolbar>

			<div class="sidenav-container">
				<router-outlet></router-outlet>
			</div>

			<footer fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
				<span class="copyright">© CANCOM {{ currentYear }}</span>
				<div class="links" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="2px">
					<div fxLayoutGap="16px">
						<a href="https://www.cancom.com" target="_blank" rel="noopener">
							<span>CANCOM Group</span>
						</a>
						<a href="mailto:{{ supportEMail }}" target="_blank" rel="noopener">
							<span>Contact</span>
						</a>
						<a href="https://www.cancom.de/en/imprint/" target="_blank" rel="noopener">
							<span>Imprint</span>
						</a>
					</div>
					<div fxLayoutGap="16px">
						<a href="https://www.cancom.de/en/privacy-protection/" target="_blank" rel="noopener">
							<span>Privacy Protection</span>
						</a>
						<a href="https://docs.cancom.page/ccp/" target="_blank" rel="noopener">
							<span>Documentation</span>
						</a>
					</div>
				</div>
			</footer>
		</mat-drawer-content>
	</mat-drawer-container>
</div>
