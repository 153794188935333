import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectDialogOption<TData = any> {
	value: TData;
	name: string;
}

export interface SelectDialogOptions<TData = any> {
	headline: string;
	description?: string;
	multiselect?: boolean;
	values: SelectDialogOption<TData>[];
	defaultValue?: TData;
	callback?: (val: TData | TData[]) => Promise<string | undefined>;
}

@Component({
	selector: 'ccp-select-dialog',
	templateUrl: './select-dialog.component.html',
	styleUrls: ['./select-dialog.component.scss'],
	standalone: false,
})
export class SelectDialogComponent<TData = any> {
	public isUpdating = false;
	public headline: string;
	public description?: string;
	public callback?: (val: TData | TData[]) => Promise<string | undefined>;

	public error = '';

	public selectForm: any;

	constructor(
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: SelectDialogOptions,
		private dialogRef: MatDialogRef<SelectDialogComponent<TData>>
	) {
		this.headline = data.headline;
		this.description = data.description;
		this.callback = data.callback;
		this.selectForm = fb.control(data.defaultValue);
	}

	public async update() {
		this.isUpdating = true;
		let result: any = undefined;

		let value = this.selectForm.value;
		if (value === null && this.data.multiselect) {
			value = [];
		}

		if (this.callback) {
			try {
				result = await this.callback(value);
			} catch (err) {
				if (err instanceof Error) {
					result = err.message;
				} else if (err instanceof HttpErrorResponse) {
					result = err.error.message;
				} else {
					result = err;
				}
			}
		}

		if (result === undefined) {
			this.close(value);
		} else {
			this.error = result;
		}

		this.isUpdating = false;
	}

	public close(result: any) {
		this.dialogRef.close(result);
	}

	public cancel() {
		this.close(undefined);
	}
}
