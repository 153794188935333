import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

export class MonthpickerDateAdapter extends NativeDateAdapter {
	constructor(matDateLocale: string) {
		super(matDateLocale);
	}

	override parse(value: string): Date | null {
		const monthAndYearRegex = /(10|11|12|0\d|\d)\/[\d]{4}/;
		if (value?.match(monthAndYearRegex)) {
			const parts = value.split('/');
			const month = Number(parts[0]);
			const year = Number(parts[1]);
			if (month > 0 && month <= 12) {
				return new Date(year, month - 1);
			}
		}
		return null;
	}

	override format(date: Date): string {
		const month = date.getMonth() + 1;
		const monthAsString = ('0' + month).slice(-2);
		const year = date.getFullYear();
		return monthAsString + '/' + year;
	}
}

@Component({
	selector: 'mcm-monthpicker',
	templateUrl: './monthpicker.component.html',
	providers: [
		{
			provide: DateAdapter,
			useClass: MonthpickerDateAdapter,
			deps: [MAT_DATE_LOCALE, Platform],
		},
	],
	standalone: false,
})
export class MonthpickerComponent {
	@Input()
	public monthAndYear: Date | null = null;

	@Input()
	public label: string | null = null;

	@Input()
	public minDate: Date | null = null;

	@Input()
	public maxDate: Date | null = null;

	@Output()
	public monthAndYearChange = new EventEmitter<Date | null>();

	public emitDateChange(event: MatDatepickerInputEvent<Date | null, unknown>): void {
		this.monthAndYearChange.emit(event.value);
	}

	public monthChanged(value: any, widget: any): void {
		this.monthAndYear = value;
		this.monthAndYearChange.emit(value);
		widget.close();
	}
}
