import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
	name: 'typeAssert',
	pure: true,
	standalone: false,
})
export class TypeAssertPipe implements PipeTransform {
	public transform<V>(value: V): any {
		return value;
	}
}
