import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SERVICE_LOOKUP } from 'src/environments/global.config';

export interface IamServiceUser {
	createdAt: string;
	createdBy: string;
	principal: string;
	description: string;
	session_hash: string;
	tenant: string;
	team: string;
}

export interface SessionData {
	sessionId: string;
	ttl: string;
	lastIssuedAt: string;
	comment: string;
}

@Injectable({
	providedIn: 'root',
})
export class IamServiceUserService {
	protected readonly api: string = environment.api.iam;
	constructor(private readonly http: HttpClient) {
		const value = SERVICE_LOOKUP('iam');
		if (value != null && value.endpoint !== this.api) {
			this.api = value.endpoint;
		}
	}

	/**
	 * Get a user by his CRN
	 * @param user The user to get details about
	 */
	public async getServiceUser(user: string): Promise<IamServiceUser> {
		return this.http.get<IamServiceUser>(this.api + '/v1/ServiceUsers/' + user).toPromise();
	}

	/**
	 * Get the list of all IAM users associated with this account
	 * @returns A list of IAM Users
	 */
	public async getServiceUsers(): Promise<IamServiceUser[]> {
		return this.http.get<IamServiceUser[]>(this.api + '/v1/ServiceUsers').toPromise();
	}
	public async getServiceSessions(user: string): Promise<IamServiceUser[]> {
		return this.http.get<IamServiceUser[]>(this.api + '/v1/Sessions/' + user).toPromise();
	}

	public async deleteServiceUser(user: string): Promise<any> {
		return this.http.delete(this.api + '/v1/ServiceUsers/' + user).toPromise();
	}

	public async deleteServiceUserTokens(user: string): Promise<any> {
		return this.http.delete(this.api + '/v1/DeleteAllSessionsForUser/' + user).toPromise();
	}

	public async recreateToken(user: string): Promise<any> {
		return this.http.put(this.api + '/v1/ServiceUsers/' + user, { resetSessionID: 'true' }).toPromise();
	}

	public async createSession(user: string, comment: string): Promise<any> {
		return this.http.post(this.api + '/v1/Sessions', { serviceUser: user, comment: comment }).toPromise();
	}

	public async createSessionToken(user: string, comment: string = ''): Promise<any> {
		return this.http.post(this.api + '/v1/Sessions', { user, comment }).toPromise();
	}

	public async deleteSession(user: string, sessionId: string): Promise<any> {
		return this.http.delete(this.api + '/v1/Sessions/' + user + '/' + sessionId).toPromise();
	}

	public async updateServiceUser(user: string, updatedServiceUserDescription: string, updatedTeam: string): Promise<any> {
		return this.http
			.put(this.api + '/v1/ServiceUsers/' + user, { description: updatedServiceUserDescription, team: updatedTeam })
			.toPromise();
	}

	public async editSessionComment(user: string, sessionId: string, sessionComment: string) {
		return this.http.put(this.api + '/v1/Sessions/' + user + '/' + sessionId, { comment: sessionComment }).toPromise();
	}
}
