<div fxFlex fxLayout="column" fxLayoutGap="8px">
	<p class="h3">Change Context</p>

	<form [formGroup]="contextForm" fxLayout="column" fxLayoutGap="8px">
		<!-- Tenant ID -->
		@if (isCancom && !isPreselectedTenant && !userService.impersonatedCRN) {
			<mcm-customer-select formControlName="tenant" (selectionFinalized)="customerSelectionFinalized()"></mcm-customer-select>
		} @else {
			<mat-form-field>
				<mat-label>Tenant</mat-label>
				<mat-select formControlName="assumableTenant">
					@for (role of assumableRoles; track $index) {
						<mat-option [value]="role.tenant">{{ role.displayName }} | {{ role.tenant }}</mat-option>
					}
				</mat-select>
			</mat-form-field>
		}

		<!-- Subaccount ID -->
		@if (suggestedSubaccounts && subaccountsEnabled) {
			<mat-form-field>
				<mat-label>Subaccount</mat-label>
				<input matInput formControlName="subaccount" [matAutocomplete]="subaccountAutofill" />
				<mat-autocomplete requireSelection #subaccountAutofill="matAutocomplete" [displayWith]="renderOption">
					<mat-option [value]="accountService.defaultSubaccount">{{ accountService.defaultSubaccount.displayName }}</mat-option>
					@for (subaccount of suggestedSubaccounts; track $index) {
						<mat-option [value]="subaccount"> {{ subaccount.displayName }} | {{ subaccount.subAccount }} </mat-option>
					}
				</mat-autocomplete>
			</mat-form-field>
		}

		<!-- Role Name -->
		<div fxFlex fxLayout="row" fxLayoutGap="12px">
			<mat-form-field>
				<mat-label>Role Type</mat-label>
				<mat-select [(value)]="roleType" (selectionChange)="changeRoleType($event.value)">
					<mat-option value="role">Role</mat-option>
					<mat-option value="managed-role">Managed-Role</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field style="width: 100%">
				<mat-label>Role Name</mat-label>
				<input matInput formControlName="role" [matAutocomplete]="roleAutofill" tabindex="2" />
				<mat-autocomplete #roleAutofill="matAutocomplete">
					@for (role of suggestedRoles; track $index) {
						<mat-option [value]="role">{{ role }}</mat-option>
					}
				</mat-autocomplete>
				@if (suggestedRoles.length === 0) {
					<mat-hint>No role suggestions available</mat-hint>
				}
			</mat-form-field>
		</div>

		<button mat-raised-button color="primary" type="submit" (click)="changeContext()" [disabled]="!contextForm.valid">
			Switch Context
		</button>
	</form>
</div>
