import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { LoadingOverlayComponent } from './loading-overlay.component';

@Injectable({
	providedIn: 'root',
})
export class LoadingOverlayService {
	overlayRef = this.overlay.create({
		positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
		hasBackdrop: true,
	});

	constructor(private overlay: Overlay) {}

	showLoader() {
		this.overlayRef.attach(new ComponentPortal(LoadingOverlayComponent));
	}

	hideLoader() {
		this.overlayRef.detach();
	}
}
