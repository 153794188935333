import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface KeyValueEditorOptions {
	header: string;
	buttonText: string;
}

@Component({
	selector: 'app-keyvalue-editor-dialog',
	templateUrl: './keyvalue-editor-dialog.component.html',
	styleUrls: ['./keyvalue-editor-dialog.component.scss'],
	standalone: false,
})
export class KeyValueEditorDialogComponent {
	public header: string = '';
	public buttonText: string = '';

	public key: string = '';
	public value: string = '';

	constructor(
		public dialogRef: MatDialogRef<KeyValueEditorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: KeyValueEditorOptions
	) {
		this.header = data.header ?? 'Key Value Editor';
		this.buttonText = data.buttonText ?? 'Ok';
	}

	public update() {
		if (this.key && this.value) {
			const result: { [key: string]: string } = {};
			result[this.key] = this.value;
			this.dialogRef.close(result);
		}
	}
}
