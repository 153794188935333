import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationBarService } from 'src/app/services/notification-bar.service';

type ConfirmationBoxCallbackFn = () => Promise<string | undefined>;

export interface ConfirmationBoxInput {
	title: string;
	message: string;
	items?: string[];
	callback?: ConfirmationBoxCallbackFn;
}

@Component({
	selector: 'app-confirmation-box',
	templateUrl: './confirmation-box.component.html',
	styleUrls: ['./confirmation-box.component.scss'],
	standalone: false,
})
export class ConfirmationBoxComponent {
	public title: string;
	public message: string;
	public items: string[] = [];
	public callback?: ConfirmationBoxCallbackFn;
	public isProcessing = false;

	constructor(
		public dialogRef: MatDialogRef<ConfirmationBoxComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ConfirmationBoxInput,
		private notificationBar: NotificationBarService
	) {
		this.title = data.title;
		this.message = data.message;
		this.items = data.items || [];
		this.callback = data.callback;
	}

	async confirmAction() {
		if (this.callback) {
			this.isProcessing = true;
			try {
				const result = await this.callback();
				this.dialogRef.close(!!result);
			} catch (err) {
				let error = 'Unknown error occured. Please try again later, or contact support.';
				if (err instanceof HttpErrorResponse) {
					if (err.error.message) {
						error = err.error.message;
					} else if (err.error.detail) {
						error = err.error.detail.map((v: any) => v.msg).join(',');
					}
				}
				this.notificationBar.notify('error', 'Error', error);
			} finally {
				this.isProcessing = false;
			}
		} else {
			this.dialogRef.close(true);
		}
	}

	cancel(): void {
		this.dialogRef.close(false);
	}
}
