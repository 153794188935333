<ul class="m2 breadcrumb flex">
	@for (bread of shownSections; track $index; let last = $last) {
		<li (click)="expand()" (keydown.enter)="expand()" tabindex="{{ $index }}">
			<span
				fxLayout="row"
				fxLayoutGap="8px"
				fxLayoutAlign="start center"
				[style.font-weight]="last ? 'bold' : 'normal'"
				[style.color]="last ? 'grey' : 'inherit'"
			>
				@if (bread.icon) {
					@if (bread.icon.startsWith('simple')) {
						<ng-icon style="--ng-icon__size: 1.2em" [name]="bread.icon"></ng-icon>
					} @else {
						<mat-icon>{{ bread.icon }}</mat-icon>
					}
				}
				@if (bread.link) {
					<a [routerLink]="bread.link" [queryParams]="bread.queryParams">{{ bread.name }}</a>
				}
				@if (!bread.link) {
					<span>{{ bread.name }}</span>
				}
			</span>
			@if (!last) {
				<mat-icon>keyboard_arrow_right</mat-icon>
			}
		</li>
	}
</ul>
