import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CustomerSelectorComponent, SelectionEvent } from '../../customer-selector/customer-selector.component';

export interface MultiTenantSelectorOptions {
	headline?: string;
	description?: string;
	callback?: (tenant: SelectionEvent[]) => Promise<string | undefined>;
}

@Component({
	selector: 'ccp-multi-tenant-selector-dialog',
	templateUrl: './multi-tenant-selector-dialog.component.html',
	styleUrls: ['./multi-tenant-selector-dialog.component.scss'],
	standalone: false,
})
export class MultiTenantSelectorDialogComponent {
	headline: string;
	isUpdating: boolean = false;
	error: string = '';
	description?: string;
	callback?: (tenants: SelectionEvent[]) => Promise<string | undefined>;

	tenants: {
		name: string;
		id: string;
	}[] = [];

	constructor(
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<MultiTenantSelectorDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: MultiTenantSelectorOptions
	) {
		this.headline = data.headline || 'Select one or more tenants';
		this.description = data.description;
		this.callback = data.callback;
	}

	public close(value: any) {
		this.dialogRef.close(value);
	}

	public addTenant(tenant: CustomerSelectorComponent) {
		if (tenant.optionSelected) {
			this.tenants.push(tenant.optionSelected);
		}
	}

	public deleteTenant(index: number) {
		this.tenants.splice(index, 1);
		this.tenants = [...this.tenants];
	}

	public async update() {
		this.isUpdating = true;
		let result: any = undefined;

		let value = this.tenants;
		if (value === null) {
			value = [];
		}

		if (this.callback) {
			try {
				result = await this.callback(value);
			} catch (err) {
				if (err instanceof Error) {
					result = err.message;
				} else if (err instanceof HttpErrorResponse) {
					result = err.error.message;
				} else {
					result = err;
				}
			}
		}

		if (result === undefined) {
			this.close(value);
		} else {
			this.error = result;
		}

		this.isUpdating = false;
	}
}
