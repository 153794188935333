import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-confirmation-dialog',
	templateUrl: './stay-on-page-dialog.component.html',
	standalone: false,
})
export class StayOnPageDialogComponent {
	constructor(private readonly dialogRef: MatDialogRef<StayOnPageDialogComponent, boolean>) {}

	onClickCancel() {
		this.dialogRef.close(false);
	}

	onClickConfirm() {
		this.dialogRef.close(true);
	}
}
