import { Component } from '@angular/core';

@Component({
	selector: 'coming-soon',
	templateUrl: './coming-soon.component.html',
	styleUrls: ['./coming-soon.component.scss'],
	standalone: false,
})
export class ComingSoonComponent {
	constructor() {}
}
