import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

export type NotificationBarType = 'info' | 'error' | 'success' | 'warning';

interface NotificationBarData {
	type?: NotificationBarType;
	header?: string;
	content?: string;
}

@Component({
	selector: 'notification-bar',
	templateUrl: './notification-bar.component.html',
	styleUrls: ['./notification-bar.component.scss'],
	standalone: false,
})
export class NotificationBarComponent {
	public type: NotificationBarType = 'info';
	public header: string | undefined = undefined;
	public content: string | undefined = undefined;

	constructor(
		private _snackRef: MatSnackBarRef<NotificationBarComponent>,
		@Inject(MAT_SNACK_BAR_DATA) public data: NotificationBarData
	) {
		if (data) {
			if (data.type) {
				this.type = data.type;
			}
			if (data.header) {
				this.header = data.header;
			}
			if (data.content) {
				this.content = data.content;
			}
		}
	}

	public close() {
		this._snackRef.dismiss();
	}
}
